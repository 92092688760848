@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type='text'],
  [type='password'],
  [type='email'],
  [type='number'],
  [type='url'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='week'],
  [type='time'],
  [type='search'],
  [type='tel'],
  [type='radio'],
  select,
  select[multiple],
  textarea {
    @apply appearance-none block w-full px-3 py-3 border border-slate-300 rounded-md shadow-sm placeholder-slate-400 focus:outline-none sm:text-sm dark:bg-slate-400 dark:text-white dark:placeholder-white;
  }
}

html {
  @apply min-h-screen overscroll-none;
}

body {
  @apply min-h-screen flex flex-col overflow-y-scroll;
}
